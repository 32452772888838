/**
 * Implementazione semplificata di SplitText
 */
export class CustomSplitText {
  constructor(target, options = {}) {
    this.element = typeof target === 'string' ? document.querySelector(target) : target;
    this.options = options;
    this.lines = [];
    this.words = [];
    this.chars = [];
    
    this.split();
  }

  split() {
    const content = this.element.innerHTML;
    this.element.innerHTML = '';
    
    if (this.options.type.includes('lines') || this.options.type.includes('words')) {
      // Creiamo una struttura temporanea per calcolare le linee
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;
      tempDiv.style.position = 'absolute';
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.width = `${this.element.offsetWidth}px`;
      document.body.appendChild(tempDiv);
      
      // Dividiamo in parole
      const words = content.trim().split(/\s+/);
      let lineArray = [];
      let currentLine = [];
      let previousTop = -1;
      
      words.forEach((word, index) => {
        const wordSpan = document.createElement('span');
        wordSpan.innerHTML = word + (index < words.length - 1 ? ' ' : '');
        
        if (this.options.wordsClass) {
          wordSpan.className = this.options.wordsClass;
        }
        
        tempDiv.appendChild(wordSpan);
        
        const rect = wordSpan.getBoundingClientRect();
        
        if (previousTop !== -1 && rect.top > previousTop) {
          // Nuova linea
          lineArray.push(currentLine);
          currentLine = [wordSpan];
        } else {
          currentLine.push(wordSpan);
        }
        
        previousTop = rect.top;
        this.words.push(wordSpan);
      });
      
      if (currentLine.length > 0) {
        lineArray.push(currentLine);
      }
      
      // Ricreare le linee
      lineArray.forEach(line => {
        const lineDiv = document.createElement('div');
        if (this.options.linesClass) {
          lineDiv.className = this.options.linesClass;
        }
        
        line.forEach(word => {
          lineDiv.appendChild(word.cloneNode(true));
        });
        
        this.element.appendChild(lineDiv);
        this.lines.push(lineDiv);
      });
      
      document.body.removeChild(tempDiv);
    }
    
    if (this.options.type.includes('chars')) {
      const text = content;
      const chars = text.split('');
      
      chars.forEach(char => {
        if (char.trim() !== '') {
          const charSpan = document.createElement('span');
          charSpan.innerHTML = char;
          
          if (this.options.charsClass) {
            charSpan.className = this.options.charsClass;
          }
          
          this.element.appendChild(charSpan);
          this.chars.push(charSpan);
        } else {
          // Spazio
          this.element.appendChild(document.createTextNode(' '));
        }
      });
    }
  }
  
  revert() {
    // Ripristina il contenuto originale
    this.element.innerHTML = this.originalContent;
  }
} 