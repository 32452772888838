/**
 * Implementazione semplificata di ScrambleTextPlugin
 */
export class CustomScrambleText {
  constructor() {
    this.chars = "!@#$%^&*()_+{}|:<>?~`1234567890-=[]\\;',./";
  }

  /**
   * Applica l'effetto scramble al testo
   * @param {Element} target - L'elemento da animare
   * @param {Object} options - Opzioni di configurazione
   * @param {string} options.text - Il testo finale
   * @param {string} options.chars - I caratteri da usare per lo scramble
   * @param {number} options.speed - La velocità dell'animazione
   * @param {Function} onComplete - Callback al completamento
   */
  scramble(target, options, onComplete) {
    const element = typeof target === 'string' ? document.querySelector(target) : target;
    const finalText = options.text || element.textContent;
    const scrambleChars = options.chars || this.chars;
    const speed = options.speed || 0.5;
    
    let currentText = element.textContent;
    const finalLength = finalText.length;
    let progress = 0;
    let intervalId;
    
    // Funzione di aggiornamento
    const update = () => {
      progress += speed;
      let newText = '';
      
      for (let i = 0; i < finalLength; i++) {
        if (i < progress) {
          // Carattere definitivo
          newText += finalText[i];
        } else {
          // Carattere scramble casuale
          newText += scrambleChars[Math.floor(Math.random() * scrambleChars.length)];
        }
      }
      
      element.textContent = newText;
      
      if (progress >= finalLength) {
        clearInterval(intervalId);
        element.textContent = finalText;
        if (onComplete) onComplete();
      }
    };
    
    // Avvia l'animazione
    intervalId = setInterval(update, 30);
    
    return {
      cancel: () => clearInterval(intervalId)
    };
  }
}

// Aggiungiamo il metodo scrambleText a GSAP
export const addScrambleText = (gsap) => {
  gsap.registerEffect({
    name: "scrambleText",
    effect: (targets, config) => {
      const scrambler = new CustomScrambleText();
      const tl = gsap.timeline();
      
      Array.from(targets).forEach(target => {
        tl.add(() => {
          scrambler.scramble(target, {
            text: config.text,
            chars: config.chars,
            speed: config.speed
          });
        });
      });
      
      return tl;
    },
    defaults: { text: "", chars: "!@#$%^&*()_+{}|:<>?~`1234567890-=[]\\;',./", speed: 0.5 },
    extendTimeline: true
  });
}; 