import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'
// Rimuovo l'import di SplitText
// import {SplitText} from 'gsap/SplitText'
// Importo la nostra implementazione
import {CustomSplitText} from '../animations/utils/CustomSplitText'

gsap.registerPlugin(ScrollTrigger)

export const revealTextByLetter = (textContainer, speed, ease, forceLineHeight, onEnter) => {


  if(textContainer && textContainer.classList.contains('uppercase'))
    forceLineHeight = false;

  const
    toSplitText = new CustomSplitText(textContainer, {
      type: 'chars',
      charsClass: 'text__char'
    }),
    chars = toSplitText.chars

  gsap.set(chars, {
    yPercent: 100,
    opacity: 0
  })

  gsap.to(chars, {
    yPercent: 0,
    opacity: 1,
    stagger: .04,
    duration: speed ? speed : .5,
    ease: ease ? ease : 'ease.out',
    scrollTrigger: {
      trigger: textContainer,
      start: 'top 88%',
      onEnter: onEnter ? onEnter() : null
    }
  })
}
