import {DOM} from './_variables';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {revealTextByLetter} from "./animations/reveal-text-by-letter";
import { Draggable } from "gsap/Draggable";
import { addScrambleText } from './animations/utils/CustomScrambleText';

gsap.registerPlugin(ScrollTrigger, Draggable);
addScrambleText(gsap);

const
  animations = {
    accordionAnimation: () => import('./_accordions'),
    revealTextByLine: () => import('./animations/reveal-text-by-line'),
    revealTextByLetter: () => import('./animations/reveal-text-by-letter'),
    scrollReveal: () => import('./animations/scroll-reveal')
  },
  toRevealTexts = document.querySelectorAll('.text-reveal'),
  toRevealTextsByLetter = document.querySelectorAll('.text-reveal-by-letter')

export default {
  splitAndRevealText: () => {
    if (toRevealTexts.length > 0) {
      animations.revealTextByLine().then(module => {
        for (const text of toRevealTexts) {
          module.revealTextByLine(text)
        }
      })
    }

    if (toRevealTextsByLetter.length > 0) {
      for (const text of toRevealTextsByLetter) {
        animations.revealTextByLetter().then(module => {
          module.revealTextByLetter(text)
        })
      }
    }
  },
  scrollReveal: () => {
    animations.scrollReveal().then(module => {
      module.reveal(DOM.toRevealElements)
    })
  },
  menu: () => {
    const
      burger = DOM.html.querySelector('.burger'),
      burgerMenu = DOM.html.querySelector('.top-navigation__nav'),
      closeMenu = DOM.html.querySelector('.close-menu'),
      topNavigation = document.querySelector('.top-navigation'),
      headerLinks = document.querySelectorAll('.menu-header li a'),
      logoTextMedia = document.getElementById('logo-text-media'),
      logoCircle = document.getElementById('logo-circle'),
      logo = document.querySelector('.logo'),
      hiddenMenuItems = document.querySelectorAll('.reveal-menu-item')

    for (const link of headerLinks) {
      link.addEventListener('click', () => {
        burgerMenu.classList.remove('open')
        burger.classList.remove('hidden')

        gsap.to(hiddenMenuItems, {
          y: '100%',
          duration: .00001,
          stagger: {
            each: .00001,
            onStart: function () {
              this.targets()[0].classList.remove('active')
            }
          }
        })
      })
    }

    gsap.to(logoTextMedia, {
      y: -200,
      scrollTrigger: {
        start: 0,
        end: 400,
        scrub: true,
      }
    })

    gsap.to(logoCircle, {
      opacity: 0,
      scrollTrigger: {
        start: 0,
        end: 400,
        scrub: true,
      }
    })

    closeMenu.addEventListener('click', () => {
      burgerMenu.classList.remove('open')
      burger.classList.remove('hidden')

      gsap.to(hiddenMenuItems, {
        y: '100%',
        duration: .00001,
        stagger: {
          each: .00001,
          onStart: function () {
            this.targets()[0].classList.remove('active')
          }
        }
      })
    })

    burger.addEventListener('click', () => {
      burgerMenu.classList.add('open')
      burger.classList.add('hidden')

      gsap.to(hiddenMenuItems, {
        y: 0,
        stagger: {
          each: .2,
          onStart: function () {
            this.targets()[0].classList.add('active')
          }
        }
      })
    })


    document.addEventListener('keyup', (event) => {
      if(event.keyCode === 27) {
        burgerMenu.classList.remove('open')
        burger.classList.remove('hidden')

        gsap.to(hiddenMenuItems, {
          y: '100%',
          duration: .00001,
          stagger: {
            each: .00001,
            onStart: function () {
              this.targets()[0].classList.remove('active')
            }
          }
        })
      }
    })


  },
  textChanger: () => {
    let index = 0

    const
      changer = document.querySelector('.changer'),
      changeText = () => {
        index === type.length - 1 ? index = 0 : index++

        gsap.set(changer, {
          opacity: 0,
          onComplete: function () {
            gsap.to(changer, {
              duration: 1.5,
              scrambleText: {
                text: type[index],
                chars: '!abcdefghijklmnopqrstxwz@!-&"|%',
                speed: 0.05,
              }
            })

            gsap.set(changer, {
              opacity: 1
            })
          }
        })
      }

    if (changer) {
      changeText()

      setInterval(function () {
        changeText()
      }, 3000)
    }
  },
  accordions: () => {
    const
      accordions = DOM.html.querySelectorAll('.accordion'),
      detailsList = DOM.html.querySelectorAll('.details')

    if (accordions.length > 0) {
      for (const accordion of accordions) {
        const
          title = accordion.querySelector('.accordion__title'),
          collapsible = accordion.querySelector('.accordion__collapsible'),
          openCallBack = () => accordion.classList.add('accordion--active'),
          closeCallBack = () => accordion.classList.remove('accordion--active')

        let isOpen = false


        title.addEventListener('click', () => {
          const alreadyOpened = document.querySelector('.accordion--active')

          if (alreadyOpened && alreadyOpened !== accordion) {
            alreadyOpened.querySelector('.accordion__title').click()
          }

          if (!isOpen) {
            animations.accordionAnimation().then((module) => module.open(collapsible, openCallBack))
          } else {
            animations.accordionAnimation().then((module) => module.close(collapsible, closeCallBack, 0))
          }

          isOpen = !isOpen
        })
      }
    }

    if (detailsList.length) {
      for (const details of detailsList) {
        const
          title = details.querySelector('.open-list'),
          collapsible = details.querySelector('.details-list'),
          openCallBack = () => {
            collapsible.classList.add('details-list--active')
          },
          closeCallBack = () => {
            collapsible.classList.remove('details-list--active')
          }

        let isOpen = false

        title.addEventListener('click', () => {
          if (!isOpen) {
            animations.accordionAnimation().then((module) => module.open(collapsible, openCallBack))

          } else {
            animations.accordionAnimation().then((module) => module.close(collapsible, closeCallBack, 150))
          }

          isOpen = !isOpen
        })
      }
    }
  },
  /*  logoAnimation: () => {
      const
        logoArch = document.getElementById('arch'),
        logoBall = document.getElementById('ball')

      if (logoArch) {
        const ballAnimation = gsap.timeline({paused: true})

        ballAnimation.to(
          logoBall, {
            opacity: 1,
            y: 0,
            duration: 2,
            ease: "elastic.out(1, 0.3)"
          })
          .to(logoArch, {
            rotate: 28,
            y: 5,
            duration: .3
          }, '-=1.75')
          .to(logoArch, {
            y: 0,
            rotate: 0,
            duration: 1,
            ease: "elastic.out(1, 0.5)"
          }, '-=1.4')

        ballAnimation.play()
      }
    },*/
  marquee: () => {
    let loops = gsap.utils.toArray('.marquee__els-container').map((line, i) => {
      const innerElements = line.querySelectorAll('.marquee__el'),
        horizontalLoop = (items, config) => {
          /* Based off GreenSock's pen here: https://codepen.io/GreenSock/pen/jOBBVjr  */

          items = gsap.utils.toArray(items);
          config = config || {}
          let tl = gsap.timeline({
              repeat: config.repeat,
              paused: config.paused,
              defaults: {ease: "none"},
              onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
            }),
            length = items.length,
            startX = items[0].offsetLeft,
            times = [],
            widths = [],
            xPercents = [],
            curIndex = 0,
            pixelsPerSecond = (config.speed || 1) * 100,
            snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
            totalWidth, curX, distanceToStart, distanceToLoop, item, i
          gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
            xPercent: (i, el) => {
              let w = widths[i] = parseFloat(gsap.getProperty(el, "width", "px"))
              xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / w * 100 + gsap.getProperty(el, "xPercent"))
              return xPercents[i]
            }
          });
          gsap.set(items, {x: 0});
          totalWidth = items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0);
          for (i = 0; i < length; i++) {
            item = items[i];
            curX = xPercents[i] / 100 * widths[i];
            distanceToStart = item.offsetLeft + curX - startX;
            distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
            tl.to(item, {
              xPercent: snap((curX - distanceToLoop) / widths[i] * 100),
              duration: distanceToLoop / pixelsPerSecond
            }, 0)
              .fromTo(item, {xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)}, {
                xPercent: xPercents[i],
                duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
                immediateRender: false
              }, distanceToLoop / pixelsPerSecond)
              .add("label" + i, distanceToStart / pixelsPerSecond);
            times[i] = distanceToStart / pixelsPerSecond;

            item.addEventListener("mouseenter", () => gsap.to(tl, {timeScale: 0, overwrite: true}));
            item.addEventListener("mouseleave", () => gsap.to(tl, {timeScale: 1, overwrite: true}));

          }

          function toIndex(index, vars) {
            vars = vars || {};
            (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
            let newIndex = gsap.utils.wrap(0, length, index),
              time = times[newIndex];
            if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
              vars.modifiers = {time: gsap.utils.wrap(0, tl.duration())};
              time += tl.duration() * (index > curIndex ? 1 : -1);
            }
            curIndex = newIndex;
            vars.overwrite = true;
            return tl.tweenTo(time, vars);
          }

          tl.next = vars => toIndex(curIndex + 1, vars);
          tl.previous = vars => toIndex(curIndex - 1, vars);
          tl.current = () => curIndex;
          tl.toIndex = (index, vars) => toIndex(index, vars);
          tl.times = times;
          if (config.reversed) {
            tl.vars.onReverseComplete();
            tl.reverse();
          }
          return tl;
        }

      horizontalLoop(innerElements, {
        repeat: -1,
        speed: .5,
        reversed: false,
        paddingRight: parseFloat(gsap.getProperty(innerElements[0], 'marginRight', 'px'))
      })
    })
    Draggable.create(".marquee__els-container", {
      type: "x",
      bounds: { minX: -1000, maxX: 0 }
    });

    let currentScroll = 0;
    let scrollDirection = 1;

    window.addEventListener('scroll', () => {
      let direction = (window.pageYOffset > currentScroll) ? 1 : -1;
      if (direction !== scrollDirection) {
        loops.forEach(tl => gsap.to(tl, {timeScale: direction, overwrite: true}));
        scrollDirection = direction;
      }
      currentScroll = window.pageYOffset;
    })
  },
  animatedNumbers: () => {
    const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

    const items = document.querySelectorAll('.animated-number')

    if (items.length > 0) {
      gsap.from(items, {
        textContent: 0,
        duration: 1,
        ease: "power1.in",
        snap: {textContent: 1},
        stagger: {
          each: .1,
          onUpdate: function () {
            this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
          },
        },
        scrollTrigger: {
          trigger: items[0],
          start: 'top 85%',
          once: true
        }
      })
    }
  },
  updateNumbers: () => {
    const toUpdateNumbers = document.querySelectorAll('.update-number')

    if (toUpdateNumbers.length > 0) {
      for (const number of toUpdateNumbers) {
        gsap.set(number.querySelector('.update-number__next'), {
          left: number.querySelector('.update-number__zero').getBoundingClientRect().width + 3,
        })
      }


      gsap.to('.update-number__prev', {
        y: '-100%',
        duration: 1,
        ease: "power1.in",
        stagger: {
          each: .2,
        },
        scrollTrigger: {
          trigger: toUpdateNumbers[0],
          start: 'top 85%',
          once: true
        }
      })

      gsap.to('.update-number__next', {
        y: 0,
        duration: 1,
        ease: "power1.in",
        stagger: {
          each: .2,
        },
        scrollTrigger: {
          trigger: toUpdateNumbers[0],
          start: 'top 85%',
          once: true
        }
      })
    }
  }
}
