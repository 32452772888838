// core version + navigation, pagination modules:
import Swiper, {Navigation} from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';

export const slider = () => {
  const slider = document.querySelector('.swiper')

  if (slider) {
    // init Swiper:
    const swiper = new Swiper('.swiper', {
      modules: [Navigation],
      speed: 1000,
      // Optional parameters
      loop: true,
      lazy: true,
      // Navigation arrows
      navigation: {
        nextEl: slider.querySelector('.swiper-button-next'),
        prevEl: slider.querySelector('.swiper-button-prev'),
      }
    })
  }
}
