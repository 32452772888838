import {gsap} from "gsap";

export const cursor = () => {
  let scaleVal = 1,
    firstMove = true

  const
    cursorOutline = document.getElementById('cursor-outline'),
    cursorAtom = document.getElementById('cursor-atom'),
    cursorAtomSpan = cursorAtom.querySelector('span'),
    pageLinks = document.querySelectorAll('a'),
    pageButtons = document.querySelectorAll('button'),
    bibliographyLinks = document.querySelectorAll('.bibliography-text strong'),
    videoPreview = document.querySelector('.insight__video-preview__container'),
    showMore = document.querySelectorAll('.cursor-show-more'),
    increaseCursor = () => scaleVal = 0.6,
    decreaseCursor = () => scaleVal = 1

  if (cursor) {
    document.body.addEventListener('mousemove', (event) => {
      if (firstMove) {
        firstMove = false;
        gsap.to([cursorOutline, cursorAtom], {
          opacity: 1,
          duration: .4
        })
      }
      gsap.to(cursorOutline, {
        x: event.pageX - (window.innerWidth / 2),
        y: event.pageY - (window.innerHeight / 2),
        scale: scaleVal,
        duration: .4,
        delay: .1
      })

      gsap.set(cursorAtom, {
        x: event.pageX - (window.innerWidth / 2),
        y: event.pageY - (window.innerHeight / 2),
        duration: .2,
      })
    })

    if (pageLinks.length > 0) {
      for (const link of pageLinks) {
        link.addEventListener('mouseenter', increaseCursor)
        link.addEventListener('mouseleave', decreaseCursor)
      }
    }

    if (pageButtons.length > 0) {
      for (const button of pageButtons) {
        button.addEventListener('mouseenter', increaseCursor)
        button.addEventListener('mouseleave', decreaseCursor)
      }
    }

    if (bibliographyLinks.length > 0) {
      for (const link of bibliographyLinks) {
        link.addEventListener('mouseenter', increaseCursor)
        link.addEventListener('mouseleave', decreaseCursor)
      }
    }

    if (videoPreview) {
      videoPreview.addEventListener('mouseenter', increaseCursor)
      videoPreview.addEventListener('mouseleave', decreaseCursor)
    }

    if (showMore.length > 0) {
      for (const link of showMore) {
        link.addEventListener('mouseenter', () => {
          gsap.to(cursorAtom, {
            width: 100,
            height: 100,
            margin: -50,
            duration: .3
          })

          gsap.set(cursorAtomSpan, {
            opacity: 1,
            delay: .2
          })

          gsap.to(cursorOutline, {
            opacity: 0,
            duration: .2
          })
        })

        link.addEventListener('mouseleave', () => {
          gsap.set(cursorAtomSpan, {
            opacity: 0
          })

          gsap.set(cursorAtomSpan, {
            opacity: 0,
            delay: .2
          })

          gsap.to(cursorAtom, {
            width: 6,
            height: 6,
            duration: .3,
            margin: 0
          })
          

          gsap.to(cursorOutline, {
            opacity: 1,
            duration: .2
          })
        })
      }
    }
  }
}
