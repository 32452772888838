'use strict';

// postcss stylesheets
import './../styles/_tailwind.pcss'
import './../styles/main.pcss'

import global from './_global';
import animations from './_animations';
import { slider } from "./_slider";
import { cursor } from "./_cursor";

const
  lazyLoading = () => import('./_lazy-load'),
  hideLoader = () => import('./animations/hide-loader')

/* force scrolling to top to avoid page bug*/

if (document.documentElement.scrollTop < window.innerHeight / 2) {
  window.scrollTo(0, 0);
}

document.addEventListener("DOMContentLoaded", () => {
  global.pageLoadedChecker();
  global.getSreenSize();
  animations.marquee()
  animations.splitAndRevealText()
  animations.scrollReveal()
  /*  animations.logoAnimation()*/
  animations.accordions()
  animations.menu()
  animations.textChanger()
  animations.animatedNumbers()
  animations.updateNumbers()
  slider()

  cursor()
})

document.addEventListener("everyThingIsLoaded", () => {
  hideLoader().then(module => module.hideLoader())
})

document.addEventListener('everyThingIsLoaded', () => {
  lazyLoading().then((module) => module.init())
})
